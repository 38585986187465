
import React from "react";
import "./Landing.css";
import Particles from "react-particles-js";
const Landing = () => {
  return (
    <div style={{ backgroundColor: "#000", height: "100vh" }}>
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute" }}>
          <Particles
            params={{
              particles: {
                number: {
                  value: 50,
                },
                size: {
                  value: 3,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};





export default Landing;
